import React from 'react';
// import Row from "reactstrap/es/Row";
import SectionHeader from "../../../components/SectionHeader";
import {deepDiveData} from "../../../data/QuickStartRecords";
// import Col from "reactstrap/es/Col";
import DashboardBigIconLink from "../../../components/DashboardBigIconLink";

function DeepDive(props) {
    return (

        <React.Fragment>

            <SectionHeader title={'country level deep dive'} subTitle={'Dashboards for users with country-specific projects or portfolios'}/>

            <div>

                {deepDiveData.map((data)=>
                    <div md={4}>
                        <DashboardBigIconLink {...data} />
                    </div>
                )}

            </div>
        </React.Fragment>

    );
}

export default DeepDive;
