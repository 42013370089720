import React from "react";
import { guidesAndInfoRecords } from "../../data/GuidesAndInfoRecords";
import DashboardLink from "../../components/DashboardLink";
import SectionHeader from "../../components/SectionHeader";

const GuidesAndInformation = () => {
  return (
    <div>
      <SectionHeader
        title={"Guides and information​​​​​​​​​​​​​​"}
        subTitle={"Make the most of the tools"}
      />
      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {guidesAndInfoRecords.map((guidesAndInfoRecord, i) => {
            return (
              <div key={i} style={{ marginBottom: "1.5em", flex: "0 0 50%", maxWidth: "50%" }}>
                <DashboardLink {...guidesAndInfoRecord} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GuidesAndInformation;
