import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import { companionGuides } from "../../../data/GuidesAndInfoRecords";
import Link from "../../../components/LinksSection/Link";

const CompanionGuides = () => {
  return (
    <div>
      <SectionHeader title={"Companion Guides for IMAP Portal"} />
      <div style={{ padding: "0 40px" }}>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {companionGuides &&
          companionGuides.map((data, index) => (
            <div key={index} md={4}>
              <Link {...data} />
            </div>
          ))}
      </div>
      </div>
    </div>
  );
};

export default CompanionGuides;
