import React from 'react';
import PageHeader from "../../components/PageHeader";

function SamplePage(props) {
    return (

        <div>

           <PageHeader title={' Sample page'}/>
        </div>

    );
}

export default SamplePage;
