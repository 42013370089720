import React from 'react';
import './styles.css';

function BreadCrumb({elements}) {
    return (

        <div className={'breadcrumb'}>

            {elements && elements.map((e, i)=>{

                return <span key={i} className={'bc-element'}> {e} </span>
            })}

        </div>

    );
}

export default BreadCrumb;
