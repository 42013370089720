import React from "react";
// import Row from "reactstrap/es/Row";
import SectionHeader from "../../../components/SectionHeader";
import { overViewData } from "../../../data/QuickStartRecords";
// import Col from "reactstrap/es/Col";
import DashboardBigIconLink from "../../../components/DashboardBigIconLink";

function Overview(props) {
  return (
    <React.Fragment>
      <SectionHeader
        title={"regional or global overview"}
        subTitle={
          "Dashboard for users with a strategic-level perspective or a broad geographic portfolio"
        }
      />

      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {overViewData.map((data) => (
            <div
              style={{
                marginBottom: "1.5em",
                flex: "0 0 33.3%",
                maxWidth: "33.3%",
              }}
            >
              <DashboardBigIconLink {...data} />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Overview;
