import React from 'react';
import Image from "../Image";
import './styles.css';

function SectionTile({data}) {

    const {image, title, link} = data;
    return (
        <a href={link} target={'_blank'}>
            <div className={'image-tile'}>

                <Image className={'mx-auto w-100'} src={image}/>

                <div className={'title'}>{title}</div>

            </div>
        </a>

    );
}

export default SectionTile;
