 import { config } from "./config";
 export const url = config.application.apiUrl;
 export const key = config.application.apiKey;

 export const options = {
   headers: {
     "x-api-key": key,
     "Content-Type": "application/json",
   },
 };
