import React from "react";

export const deepDiveData = [
    {
        icon: 'front-end/icons/quick-start-guide/Conflict_Actors_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Conflict_Actors_Hover.png',
        heading: "Actor-specific view",
        subHeading: "Where is an armed group active?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Fragility_Instability_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Fragility_Instability_Hover.png',
        heading: "Subnational Views",
        subHeading: "Where are conflict events distributed?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/ProvinceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Country_Overview_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Country_Overview_Hover.png',
        heading: "Deep dive Into Things",
        subHeading: "What forms of violence has my country experienced recently? Are these new trends?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryOverviewDashboard_16118615590170/OverviewDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
];

export const overViewData = [
    {
        icon: 'front-end/icons/quick-start-guide/Global-Conflict-Protect-Tracker_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Global-Conflict-Protect-Tracker_On.png',
        heading: "Cross National Comparison",
        subHeading: "How does the level of conflict in my region compare to others?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Regional-Conflict-Actor_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Regional-Conflict-Actor_Hover.png',
        heading: "Regional Actor Trends",
        subHeading: "What conflict actors are active in my region?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Monthly_Tracker_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Monthly_Tracker_On.png',
        heading: "Country Overview",
        subHeading: "What does conflict look like in this set of countries over the past 12 months?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryOverviewDashboard_16118615590170/OverviewDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const subNationalOtherViews = [
    {
        icon: 'front-end/icons/quick-start-guide/Subnational_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Subnational_1_Hover.png',
        heading: "",
        subHeading: "Explore levels of instability by province.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/ProvinceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Subnational_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Subnational_2_Hover.png',
        heading: "",
        subHeading: "How are conflict events dispersed geographically?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Subnational_3_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Subnational_3_Hover.png',
        heading: "",
        subHeading: "I want to see province level trends over the last year.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/CountryDashboard?Select%20Period=12%20Months&Select%20Dos%20Country=Afghanistan?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const actorsInvolvedInConflict1 = [
    {
        icon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_Star_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_Star_Hover.png',
        heading: "",
        subHeading: "Where are state, non-state and external forces active?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_Hover.png',
        heading: "",
        subHeading: "What tactics are armed groups using?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const quickStatistics = [
    {
        icon: 'front-end/icons/quick-start-guide/Quick-Statistics_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Quick-Statistics_1_Hover.png',
        heading: "",
        subHeading: "Give me a high-level world conflict overview.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Quick-Statistics_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Quick-Statistics_2_Hover.png',
        heading: "",
        subHeading: "Give me country specific figures.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryOverviewDashboard_16118615590170/OverviewDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const trendsOverTime = [
    {
        icon: 'front-end/icons/quick-start-guide/Trends-Over-Time_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Trends-Over-Time_1_Hover.png',
        heading: "",
        subHeading: "Are deliberate civilian killings increased or decreasing?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryConflictExplorer/ConflictExplorer?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Trends-Over-Time_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Trends-Over-Time_2_Hover.png',
        heading: "",
        subHeading: "What provinces are conflict fatalities occurring in?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/ProvinceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const crossNationalComparison = [
    {
        icon: 'front-end/icons/quick-start-guide/Cross-National-Comparison_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Cross-National-Comparison_1_Hover.png',
        heading: "",
        subHeading: "How does conflict compare across countries?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Cross-National-Comparison_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Cross-National-Comparison_2_Hover.png',
        heading: "",
        subHeading: "Where are this month’s global hotspots?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const learningNewPortfolio = [
    {
        icon: 'front-end/icons/quick-start-guide/Learning-a-New-Portfolio_1_0ff.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Learning-a-New-Portfolio_1_Hover.png',
        heading: "",
        subHeading: "I want a snapshot of a particular time period.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryConflictExplorer/ConflictExplorer?showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Learning-a-New-Portfolio_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Learning-a-New-Portfolio_2_Hover.png',
        heading: "",
        subHeading: "I want to focus on a specific type of violence/conflict.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryConflictExplorer/ConflictExplorer?showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const actorsInvolvedInConflict2 = [
    {
        icon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_1_Hover.png',
        heading: "",
        subHeading: "Which armed groups have been attacking civilians?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/IndividualConflictActorDashboard_15980195604260/IndividualActorDashboard?%20showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_2_2_Hover.png',
        heading: "",
        subHeading: "Which non-state actors should I be most concerned about?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/IndividualConflictActorDashboard_15980195604260/IndividualActorDashboard?%20showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_3_3_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Actors-Involved-in-Conflict_3_3_Hover.png',
        heading: "",
        subHeading: "What are recent trends in Sahel?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const strategicOverview = [
    {
        icon: 'front-end/icons/quick-start-guide/Strategic-Overview_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Strategic-Overview_Hover.png',
        heading: "",
        subHeading: "What are conflict trends at the global level?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]

export const victimsOfConflict = [
    {
        icon: 'front-end/icons/quick-start-guide/Victims-of-Conflict_1_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Victims-of-Conflict_1_Hover.png',
        heading: "",
        subHeading: "Who has committed the most violence against civilians?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?Cso%20Event%20Type=Deliberate%20attacks%20on%20civilians&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/quick-start-guide/Victims-of-Conflict_2_Off.png',
        hoverIcon: 'front-end/icons/quick-start-guide/Victims-of-Conflict_2_Hover.png',
        heading: "",
        subHeading: "Where are civilians being targeted the most?",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?Cso%20Event%20Type=Deliberate%20attacks%20on%20civilians&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    }
]
export const allImapDashboards = [
    {
        icon: 'front-end/icons/dashboard/Global-Trends_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Global-Trends_Hover.png',
        heading: "Global Conflict & Protest Monthly Tracker (GCPT)",
        subHeading: "Check fatalities & events by region.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/dashboard/Country_Trends_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Country_Trends_Hover.png',
        heading: "Global Trends",
        subHeading: "Check trends in fatalities and events by region",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#4"
    },
    {
        icon: 'front-end/icons/dashboard/Monthly_Tracker_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Monthly_Tracker_On.png',
        heading: "Country Overview",
        subHeading: "Facts & figures for last 12 months",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryOverviewDashboard_16118615590170/OverviewDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#1"
    },
    {
        icon: 'front-end/icons/dashboard/Conflict_Explorer_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Conflict_Explorer_On.png',
        heading: "Conflict Explorer",
        subHeading: "Customize views based on the data you need",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryConflictExplorer/ConflictExplorer?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2"
    },
    {
        icon: 'front-end/icons/dashboard/Conflict_Actors_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Conflict_Actors_Hover.png',
        heading: "Regional Conflict Actors",
        subHeading: "Groups involved in political violence & disorder",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#3"
    },
    {
        icon: 'front-end/icons/dashboard/CSO_Programs_Off.png',
        hoverIcon: 'front-end/icons/dashboard/CSO_Programs_Hover.png',
        heading: "Country Trends",
        subHeading: "Customize time frame to explore subnational trends",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/dashboard/Country_Explorer_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Country_Explorer_Hover.png',
        heading: "Individual Conflict Actor",
        subHeading: "Deep dive on transnational actors",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/IndividualConflictActorDashboard_15980195604260/IndividualActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2"
    },
    {
        icon: 'front-end/icons/dashboard/DemocracyConflict_Off.png',
        hoverIcon: 'front-end/icons/dashboard/DemocracyConflict_Hover.png',
        heading: "Democracy and Conflict",
        subHeading: "Conflict-related democracy indicators by country",
        link: "https://state.gov"
    },
    {
        icon: 'front-end/icons/dashboard/Fragility_Instability_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Fragility_Instability_Hover.png',
        heading: "Fragility & Instability",
        subHeading: "Explore indicators of conflict risk and resilience",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GFACountryPrioritizationDashboard-Final/GFACandidateCountriesStepI?:origin=viz_share_link&:embed=T#1"
    },
    {
        icon: 'front-end/icons/dashboard/Electoral_Violence_Risk_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Electoral_Violence_Risk_Hover.png',
        heading: "Electoral Violence Risk",
        subHeading: "Available to State Dept only",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ElectoralViolenceRiskDashboard/ElectoralViolenceRiskDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2\n"
    }
]
