import React from "react";

export const guidesAndInfoRecords = [
    {
        icon: 'front-end/icons/dashboard/Start_Here_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Start_Here_Hover.png',
        heading: "Start Here",
        subHeading: "Quick start guide to the complete dashboard toolkit",
        link: "/app/quick-start-guide",
        internalPage: true
    },
    {
        icon: 'front-end/icons/dashboard/Data_Overview_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Data_Overview_Hover.png',
        heading: "Data Overview",
        subHeading: "IMAP data sources and time period coverage",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/DataSourcesCoverage_16118676827720/DataCoverage?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2",
        internalPage: false
    },
    {
        icon: 'front-end/icons/dashboard/Companion_Guides_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Companion_Guides_Hover.png',
        heading: "Companion Guides",
        subHeading: "Examples use cases and work flows",
        link: "/app/companion-guides",
        internalPage: true
    },
];


export const companionGuides = [
    {
        icon: 'front-end/icons/companion-guide/StrategicOverview_Off.png',
        hoverIcon: 'front-end/icons/companion-guide/StrategicOverview_Hover.png',
        heading: "Strategic Overview",
        subHeading: "Strategic Overview",
        link: "https://usdos.sharepoint.com/:b:/r/sites/CSO/IMAP/Shared%20Documents/Companion%20Guides/Gain%20a%20Strategic%20Overview.pdf?csf=1&web=1&e=opc8s7"
    },
    {
        icon: 'front-end/icons/companion-guide/RecentTrends_Off.png',
        hoverIcon: 'front-end/icons/companion-guide/RecentTrends_Hover.png',
        heading: "Recent Trends",
        subHeading: "Recent Trends",
        link: "https://usdos.sharepoint.com/:b:/r/sites/CSO/IMAP/Shared%20Documents/Companion%20Guides/Discover%20information%20about%20Recent%20Trends.pdf?csf=1&web=1&e=ggyJqt"
    },
    {
        icon: 'front-end/icons/companion-guide/DataDeepDive_Off.png',
        hoverIcon: 'front-end/icons/companion-guide/DataDeepDive_Hover.png',
        heading: "Data Deep Dive",
        subHeading: "Data Deep Dive",
        link: "https://usdos.sharepoint.com/:b:/r/sites/CSO/IMAP/Shared%20Documents/Companion%20Guides/Take%20a%20Deep%20Dive%20into%20the%20Data.pdf?csf=1&web=1&e=lJgeT4"
    }
]
