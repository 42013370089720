import React from "react";
import Image from "../Image";
import { Link as NavLink } from "react-router-dom";

const Link = ({
  icon,
  hoverIcon,
  heading,
  subHeading,
  link,
  internalPage = false,
}) => {
  const Wrapper = internalPage
    ? ({ children }) => (
        <NavLink className={"dashboard-link link-icon-wrapper"} to={link}>
          {" "}
          {children}
        </NavLink>
      )
    : ({ children }) => (
        <a
          className={"dashboard-link link-icon-wrapper"}
          href={link}
          target={"_blank"}
        >
          {" "}
          {children}
        </a>
      );

  return (
    <Wrapper>
      <div className={"link-section-icon"}>
        <div className={"img-container"}>
          <Image className="off-icon" src={icon} />
          <Image className="hover-icon" src={hoverIcon || icon} />
        </div>
        <span>{subHeading}</span>
      </div>
    </Wrapper>
  );
};

export default Link;
