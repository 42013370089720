import axios from "axios";

export const getEnvVariables = async () => {
  let promise;
  promise = await axios
    .get("https://6ey6gb74yk.execute-api.us-east-1.amazonaws.com/Stage/imap")
    .then(({ data }) => {
      const config = {
        oidc: {
          issuer: data.GEC_OKTA_ISSUER_URL,
          clientId: data.GEC_OKTA_CLIENT_ID,
          scopes: ["openid", "profile", "email"],
          redirectUri: `${window.location.origin}/login/callback`,
          oktaLogoutUrl: data.GEC_OKTA_ISSUER_URL,
        },
        widget: {
          issuer: data.GEC_OKTA_ISSUER_URL,
          clientId: data.GEC_OKTA_CLIENT_ID,
          redirectUri: `${window.location.origin}/login/callback`,
          scopes: ["openid", "profile", "email"],
        },
        application: {
          apiKey: data.GEC_API_GW_API_KEY,
          apiUrl: `${data.GEC_API_GW_MICROSERVICES_URL}/${data.STAGE}`,
          stage: data.STAGE,
          apiRewriteKey: data.GEC_IMAGE_UPLOAD_URL,
          staticImages: data.IMAP_PORTAL_STATIC_IMAGES,
          wpImageCF: data.GEC_WORK_PRODUCT_IMAGE_CF_DISTRO,
          s3SignedURL: data.GEC_S3_GETSIGNED_URL,
        },
      };
   //   console.log("config test ", config);
      return config;
    });
  return promise;
};

export const config = await getEnvVariables();
