import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles.css";
import BreadCrumb from "../../components/BreadCrumb";
import ShortCutPanel from "../../components/ShortCutPanel";
import { useParams } from "react-router-dom";
import {options} from '../../Constants'

export default function ShortCutWp() {
  const [workProducts, setWorkProducts] = useState([]);
  let params = useParams();
  // console.log("params", params)
  const { id: match } = params;

  useEffect(() => {
    loadWorkProducts();
  }, [match]);

  const loadWorkProducts = async () => {
    axios.get(`/api/work-products/short-cut`, options).then(({ data }) => {
      setWorkProducts(data.content || []);
    });
  };

  return (
    <div style={{ padding: "0 40px" }}>
      <BreadCrumb
        elements={["WORK PRODUCTS", ">", "Conflict Observatory Reports"]}
      />
      <hr className={"separator"}></hr>
      <div className={"section-header"}>
        The Conflict Observatory is a cooperative agreement managed by the
        Bureau of Conflict & Stabilization Operations. Conflict Observatory
        products do not represent the views of the United States Government.
        Full reports, interactive content, and available translations are
        available on
        <div className={"co-div"}>
          {" "}
          <a href="https://ConflictObservatory.org" target="_blank">
            ConflictObservatory.org{" "}
          </a>
          Please direct any questions to{" "}
          <a href="mailto: CSO-CO@state.gov">CSO-CO@state.gov </a>
          or{" "}
          <a href="mailto: ConflictObservatory@state.gov">
            ConflictObservatory@state.gov{" "}
          </a>{" "}
        </div>
      </div>
      <hr className={"separator"}></hr>

      <ShortCutPanel workProducts={workProducts} />
    </div>
  );
}
