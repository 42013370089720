import React from "react";

export const csoLearnMoreRecords = [
    {
        icon: 'front-end/icons/dashboard/Programs_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Programs_Hover.png',
        heading: "CSO Programs",
        subHeading: "Learn more about CSO's global presence",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CSOQuarterlyProgramsUpdate/CSOProgramSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2",
        internalPage: false
    },
];
