import React from "react";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className={"footer"}>
      <div className={"footer-content"}>
        <Link className={"nav-link"} to="/app/home">
          Home
        </Link>

        <a
          className={"nav-link"}
          href="https://www.state.gov/about-us-bureau-of-conflict-and-stabilization-operations/"
          target={"_blank"}
        >
          About CSO
        </a>

        <a className={"nav-link"} href="mailto:csoimap@state.gov">Email Us</a>
      </div>
    </div>
  );
}
