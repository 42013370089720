import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import { allImapDashboards } from "../../../data/QuickStartRecords";
import DashboardBigIconLink from "../../../components/DashboardBigIconLink";
// import Col from "reactstrap/es/Col";
// import Row from "reactstrap/es/Row";

function IMAPDashboards(props) {
  return (
    <div>
      <SectionHeader title={"all imap dashboards"} id={"all-dashboards"} />

      <div>
        {allImapDashboards.map((data) => (
          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 33.3%",
              maxWidth: "33.3%",
            }}
            className={"mb-5"}
          >
            <DashboardBigIconLink {...data} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default IMAPDashboards;
