import React from 'react';
// import PageHeader from "../../components/PageHeader";
// import SectionHeader from "../../components/SectionHeader";
import DeepDive from "./DeepDive";
import Overview from "./Overview";
import MoreLinks from "./MoreLinks";
import "./styles.css";
import IMAPDashboards from "./lMAPDashboards";
// import {NavLink as Link} from "reactstrap";

function QuickStartGuide(props) {
    return (

        <div>

            <p className={'quickstart-header'}>
                IMAP hosts a set of 6 different dashboards that can be customized depending on what a user needs.
            </p>

            <p className={'quickstart-sub-header'}>
                Click the links below based on your questions and areas of interest, or jump to the <a href="/app/quick-start-guide#all-dashboards">full list of dashboards</a>.
                Still can't find what you're looking for? Get in touch with the <a href="mailto:csoimap@state.gov">IMAP Team</a> for customized views and ways to utilize your data.
            </p>

           <DeepDive/>
           <Overview/>
           <MoreLinks/>
           <IMAPDashboards/>
        </div>

    );
}

export default QuickStartGuide;
