import React, {useEffect, useState} from "react";
import axios from "axios";
// import Row from "reactstrap/es/Row";
// import Col from "reactstrap/es/Col";
import DashboardLink from "../../components/DashboardLink";
import {interactiveDashboards1, interactiveDashboards2} from "../../data/DashboardRecords";
import SectionHeader from "../../components/SectionHeader";
import "./styles.css";

const InteractiveDashboards = () =>{

    return(
        <div>

            <SectionHeader title={'Explore Data through interactive dashboards'}
                           id={'dashboards'}
                           subTitle={''}/>
            <div style={{ padding: "0 40px" }}>

            <div className={'-mt-5 subSectionHeader'}>Situational Awareness of Conflict and Instability</div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {interactiveDashboards1.map((guidesAndInfoRecord, i) => {
                    return (
                        <div key={i} style={{ marginBottom: "1.5em", flex: "0 0 50%", maxWidth: "50%" }}>
                            <DashboardLink {...guidesAndInfoRecord}/>
                        </div>)
                })}
            </div>

            <div className={'-mt-5 subSectionHeader'}>Early Warning and Risk Forecasting</div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {interactiveDashboards2.map((guidesAndInfoRecord, i) => {
                    return (
                        <div key={i} style={{ marginBottom: "1.5em", flex: "0 0 50%", maxWidth: "50%" }}>
                            <DashboardLink {...guidesAndInfoRecord}/>
                        </div>)
                })}
            </div>
            </div>

        </div>

    )
}

export default InteractiveDashboards;