import React from 'react';
import './styles.css';

function PageHeader({title}) {
    return (

        <div className={'page-header'}>
            <h5 className="title">{title}</h5>
        </div>

    );
}

export default PageHeader;
