import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ExpandLess, ExpandMore, Logout, Person } from "@mui/icons-material";
import Box from "@mui/material/Box";
// import { Main, Footer } from "./styles";
import { Typography } from "@mui/material";
// import { Button } from "@mui/material";
import FeaturedWorkProducts from "../../components/FeaturedWorkProducts";
import InteractiveDashboards from "../../components/InteractiveDashboards";
import GuidesAndInformation from "../../components/GuidesAndInformation";
import Regions from "../../components/Regions";
import CSOLearnMore from "../../components/CSOLearnMore";
import LinesOfEffort from "../../components/LinesOfEffort";
import Container from "@mui/material/Container";
import "./styles.css";


const Home = () => {
  return (
  <Container>
    <Box component="main" sx={{ mt: { xs: "160px", lg: "200px" }, }}>
      <div>
         <p className={'imap-tagline'}>
         <br/>
                IMAP (Instability Monitoring and Analysis Platform) harnesses data-driven  <br/>
                analysis to inform and evaluate U.S. policy, strategy, and programs <br/>
                on preventing violent conflict and promoting stability
            </p>
        </div>

            <FeaturedWorkProducts/>

            <InteractiveDashboards/>

            <GuidesAndInformation/>

            <Regions/>

            <CSOLearnMore/>

            <LinesOfEffort/>
    </Box>
 </Container>
  );
};

export default Home;
