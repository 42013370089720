import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import {
  Security,
  SecureRoute,
  LoginCallback,
  useOktaAuth,
} from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./Login";
import { config } from "./config";
import "./App.css";
import Header from "../src/components/Header";
import RegionsContextProvider from "./context/RegionsContext";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import SamplePage from "../src/pages/SamplePage";
import QuickStartGuide from "../src/pages/QuickStartGuide";
import CompanionGuides from "../src/components/GuidesAndInformation/CompanionGuides";
import WorkProducts from "../src/pages/WorkProducts";
import ShortCutWp from "../src/pages/ShortCutWp";
import { Box } from "@mui/material";

axios.defaults.baseURL = config?.application?.apiUrl || "";

// console.log(` application vars ${JSON.stringify(config.application)}  `);
const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  // console.log(`${config?.application?.apiUrl}`);

  // console.log(JSON.stringify(config.oidc));
  // console.log(` URL = ${window.location.origin}`);

  const history = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history(toRelativeUrl(originalUri || "", window.location.origin));
  };

  const PrivateRoute = ({ children }) => {
    const { oktaAuth, authState } = useOktaAuth();

    // console.log("isAuth", authState?.isAuthenticated);

    if (authState && authState.isAuthenticated){
        return children;
    } else {
      oktaAuth.signInWithRedirect();
    }
   

  };

  return (
    <>
      <CssBaseline />

      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
      >
        <RegionsContextProvider>
       
          <Routes>
            <Route
              element={
                <PrivateRoute>
                  <Header />
                  <Footer />
                </PrivateRoute>
              }
            >
              <Route
                path="/app/home"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/sample-page"
                element={
                  <PrivateRoute>
                    <Box
                      component="main"
                      sx={{ mt: { xs: "160px", lg: "200px" } }}
                    >
                      <SamplePage />
                    </Box>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/quick-start-guide"
                element={
                  <PrivateRoute>
                    <Box
                      component="main"
                      sx={{ mt: { xs: "160px", lg: "200px" } }}
                    >
                      <QuickStartGuide />
                    </Box>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/companion-guides"
                element={
                  <PrivateRoute>
                    <Box
                      component="main"
                      sx={{ mt: { xs: "160px", lg: "200px" } }}
                    >
                      <CompanionGuides />
                    </Box>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/regions/:id/work-products"
                element={
                  <PrivateRoute>
                    <Box
                      component="main"
                      sx={{ mt: { xs: "160px", lg: "200px" } }}
                    >
                      <WorkProducts />
                    </Box>
                  </PrivateRoute>
                }
              />
              <Route
                path="/app/shortcut/work-products"
                element={
                  <PrivateRoute>
                    <Box
                      component="main"
                      sx={{ mt: { xs: "160px", lg: "200px" } }}
                    >
                      <ShortCutWp />
                    </Box>
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/" element={<Navigate replace to="/app/home" />} />
            <Route path='login/callback' element={<LoginCallback  />} />
          </Routes>
        </RegionsContextProvider>
      </Security>
    </>
  );
};

export default App;
