import React from 'react';
import { Link } from "react-router-dom";
import Image from "../Image";
import './styles.css';

function RegionTile({region}) {
    return (

        <Link className={'p-0'} to={`/app/regions/${region.id}/work-products`}>
            <div className={'cursor-pointer mb-4 region-tile'} style={{ marginBottom: "1.5em" }}>
                <Image className={'off-image'} src={region.imageUrl}  />
                <Image className={'hover-image'} src={region.hoverImageUrl}  />
            </div>
        </Link>

    );
}

export default RegionTile;
