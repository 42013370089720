import React from 'react';
import Link from "./Link";
import './styles.css';

function LinksSection({links, title, className}) {
    return (
        <div className={'link-section'}>

            <div className={`title ${className ? className : ''}`}>{title}</div>

            <div className={'content'}>

                {links && links.map((link)=> <Link {...link} />)}

            </div>

        </div>

    );
}

export default LinksSection;
