import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {options} from '../Constants'


export const RegionsContext = React.createContext({});

const RegionsContextProvider = ({children}) => {
  const [regions, setRegions] = useState([]);

  useEffect(()=>{
    getRegions();
  },[]);

  const getRegions = () => {
    axios.get("/api/regions", options)
      .then((response) => {
    /*    console.debug(
          `retrieved regions response status: ${response.status}`
        ); */
        const _data = response.data.content ? response.data.content : response.data;
        setRegions(_data || [])
      })
  };


    return (
        <RegionsContext.Provider
            value={{regions}}>
            {children}
        </RegionsContext.Provider>
    )
}

export default RegionsContextProvider;
