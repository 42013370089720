import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../Image";
import { config } from "../../config";
import { signUrl, postAccess } from "../../utils/util";
import { useOktaAuth } from "@okta/okta-react";

const WorkProductPanel = ({ workProducts, region, imageType }) => {
  /*
  console.log(` region = ${region} `);
  console.log(` region.id = ${region.id} `);
  console.log(` region.name = ${region.name} `);
  console.log(` region.countries = ${region.countries} `);
   */
  const [countryMap, setCountryMap] = useState({});
  const { oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = oktaAuth.getAccessToken() && (await oktaAuth.getUser());
      setUserInfo(user);
      //    console.log("user", userInfo);
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      //     console.log("token", oktaAuth.getAccessToken());
    };

    let map = {};

    workProducts
      .sort((a, b) =>
        a.country.displayOrder < b.country.displayOrder
          ? -1
          : a.country.displayOrder > b.country.displayOrder
          ? 1
          : 0
      )
      .forEach((wp) => {
        if (!wp.country) return;

        const prevValues = map.hasOwnProperty(wp.country.name)
          ? [...map[wp.country.name]]
          : [];
        map = {
          ...map,
          [wp.country.name]: [...prevValues, wp],
        };
      });

    setCountryMap(map);
    fetchUser();
  }, [workProducts]);

  const handleClick = async (value) => {
    const val = await signUrl(value);
    // console.log("val:", val);
    await postAccess(value.id, userInfo);
    // Do something with the value, such as calling another function, updating state, etc.
  };

  return (
    <Container>
    <Card className={"work-product-panel"} sx={{ m: "16px 0" }}>
      <CardHeader className={"header"} title={region.name} />
      <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        {Object.keys(countryMap).map((key) => {
          const list = countryMap[key];
          return (
            <Accordion
              className={"accordion"}
              sx={{ "&.Mui-expanded": { m: 0 } }}
            >
              <AccordionSummary
                className={"accordion-header"}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded":
                    { m: 0 },
                }}
              >
                {key}
              </AccordionSummary>
              {list &&
                list.map((wp) => {
                  return (
                    <AccordionDetails style={{ padding: "0px 0px 0px" }}>
                      <div key={wp.id} className={"work-product-row"}>
                        <Image
                          className={"work-product-row-icon"}
                          src={wp.imageUrl}
                          onClick={async () => await handleClick(wp)}
                        />
                        <Button
                          variant="contaimed"
                          onClick={async () => await handleClick(wp)}
                        >
                          {wp.name}
                        </Button>
                        <Image
                          className={"work-product-file-type-icon"}
                          src={wp.fileTypeImageUrl}
                          onClick={async () => await handleClick(wp)}
                        />
                      </div>
                    </AccordionDetails>
                  );
                })}
            </Accordion>
          );
        })}
      </CardContent>
    </Card>
    </Container>
  );
};

export default WorkProductPanel;

export const getFileUrl = (wp) => {
  if (wp.fileSource === "URL") return wp.fileUrl;

  return `${config.application.staticImages}/files/${wp.fileUrl}`;
};
