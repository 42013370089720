import React, { useEffect, useState } from "react";
import axios from "axios";
import WorkProductPanel from "../../components/WorkProductPanel";
import BreadCrumb from "../../components/BreadCrumb";
// import IconsContainer from "../../components/IconsContainer";
// import Icon from "../../components/IconsContainer/Icon";
import Image from "../../components/Image";
// import {Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { config } from "../../config";
import { useParams } from "react-router-dom";
import {options} from '../../Constants'

const title = "FILTER BY PRODUCT TYPE";
const searchTitle = "FILTER WORK PRODUCTS";

const icons = [
  {
    icon: "front-end/icons/work-products/StoryMap_Off.png",
    hoverIcon: "front-end/icons/work-products/StoryMap_Hover.png",
    title: "Storymap",
    //uncomment to make it clickable
    //link: "https://google.com"
  },
  {
    icon: "front-end/icons/work-products/Report_Off.png",
    hoverIcon: "front-end/icons/work-products/Report_Hover.png",
    title: "Report",
    //uncomment to make it clickable
    //link: "https://google.com"
  },
  {
    icon: "front-end/icons/work-products/Tracker_Off.png",
    hoverIcon: "front-end/icons/work-products/Tracker_Hover.png",
    title: "Tracker",
    //uncomment to make it clickable
    //link: "https://google.com"
  },
  {
    icon: "front-end/icons/work-products/Dashboard_Off.png",
    hoverIcon: "front-end/icons/work-products/Dashboard_Hover.png",
    title: "Dashboard",
    //uncomment to make it clickable
    //link: "https://google.com"
  },
  {
    icon: "front-end/icons/quick-start-guide/Regional-Conflict-Actor_Off.png",
    hoverIcon:
      "front-end/icons/quick-start-guide/Regional-Conflict-Actor_Off.png",
    title: "ArcGIS",
  },
  {
    icon: "front-end/icons/quick-start-guide/CSO_Programs_Hover.png",
    hoverIcon: "front-end/icons/quick-start-guide/CSO_Programs_Off.png",
    title: "All",
  },
];

const years = [
  { label: "<All>", value: "<All>" },
  { label: "2024", value: 2024 },
  { label: "2023", value: 2023 },
  { label: "2022", value: 2022 },
  { label: "2021", value: 2021 },
  { label: "2020", value: 2020 },
  { label: "2019", value: 2019 },
  { label: "2018", value: 2018 },
  { label: "2017", value: 2017 },
];

// let metaOptions = [];
// let allMetaTags = [];
// let allWpImages = [];

const WorkProducts = () => {
  const [workProducts, setWorkProducts] = useState([]);
  const [meta, setMeta] = useState([]);
  const [region, setRegion] = useState([]);
  const [searchDate, setSearchDate] = useState("<All>");
  const [searchMeta, setSearchMeta] = useState("");
  const [productKey, setProductKey] = useState("<All>");
  const [resetSearch, setResetSearch] = useState(false);
  const [metaOptions, setMetaOptions] = useState([]);
  const [allMetaTags, setAllMetaTags] = useState([]);
  const [allWpImages, setAllWpImages] = useState([]);

  let params = useParams();
  const { id: match } = params;

  let _resetSearch = false;

  useEffect(() => {
    // console.log("match", match);
    loadMetaTagByRegionSelect(match);
    loadWorkProductImages();
    loadWorkProducts();
  }, [match]);

  //   const getQuarter = (dateVal) => {
  //     return Math.floor(dateVal.getMonth() / 3 + 1);
  //   };

  const filterWorkProductsByMeta = async (event) => {
    if (event.target.name === "reset") {
      await reset();
    }

    if (searchDate || meta) {
      /*     const newDate = new Date(searchDate);
            const qtr = getQuarter(newDate);
            const year = newDate.getFullYear();

              console.log(` qtr =  ${qtr} `) */
      // console.log(` year =   ${searchDate} `);

      // console.log(`searchDate =  ${searchDate} `);
      // console.log(`searchMeta =  ${meta} `);
      // console.log(`region.id =  ${region.id} `);
      // console.log(`productKey = ${productKey} `);

      /*       console.log(`event.target.id =  ${event.target.id} `)  */

      let urlQtr = `/api/work-products/year/?year=${searchDate}&region=${region.id}&productKey=${productKey}`;
      let urlMeta = `/api/work-products/meta/?region=${region.id}&productKey=${productKey}`;
      let urlAll = `api/work-products/ui?regionId=${region.id}`;
      let url;

      if (
        _resetSearch === true ||
        (productKey === "<All>" && searchDate === "<All>")
      ) {
        url = urlAll;
      } else {
        if (searchDate === "<All>" && productKey === "<All>") {
          url = urlAll;
        } else if (searchDate && searchDate != "<All>") {
          if (meta && meta.length > 0) {
            url = urlQtr;
          } else {
            url = urlQtr;
          }
        } else if (meta) {
          url = urlMeta;
        } else {
          url = urlAll;
        }
      }

      // console.log(`url   =  ${url} `);

      let rows = [];
      let rows_Set = new Set();
      await axios.get(url, options).then(async ({ data }) => {
        // console.log(` prior to looping data `);
        let metaSearchSplit = meta;

        if (meta && meta.length > 0) {
          data.forEach((row) => {
            // console.log(` row.meta = ${row.meta}  `);

            if (row.meta) {
              let dbMeta = row.meta.split(",");

              // console.log(` row.meta.split(',') = ${dbMeta}  `);
              metaSearchSplit.forEach((topVal) => {
                dbMeta.forEach((val) => {
                  let match =
                    topVal.trim().toLowerCase() == val.trim().toLowerCase()
                      ? true
                      : false;
                  if (match) {
                    rows_Set.add(row);
                  }
                  // console.log(` topVal =${topVal} `);
                  // console.log(` val =${val} `);
                  // console.log(` match = ${match} `);
                });
              });
              // console.log(`  FILTERED ROWS =  ${JSON.stringify(rows)}`);
            }
          });

          data = meta ? Array.from(rows_Set) : data;
        }
        await setWorkProducts(data || data.content);
      });
    } else {
      await setWorkProducts([]);
    }
  };

  const handleChange = (event) => {
    // console.log(` productKey before setValue  =  ${event.target.value} `);
    setResetSearch(false);
    setProductKey(event.target.value);
  };

  const handleSearchDateChange = (event) => {
    // console.log(` productKey before setValue  =  ${event.target.value} `);
    setResetSearch(false);
    setSearchDate(event.target.value);
  };

  const reset = async () => {
    setResetSearch(true);
    _resetSearch = true;
    setMeta([]);
    setSearchMeta("");
    setSearchDate("<All>");
    setProductKey("<All>");
  };

  const handleChangeMulti = (event) => {
    setResetSearch(false);
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setMeta(value);
    setSearchMeta(value);
    // console.log(` ${value} `);
  };

  const filterWorkProducts = (val) => {
    let url = `/api/work-products/ui?regionId=${region.id}&image.name=${val}`;

    if (val === "<All>") url = `/api/work-products/ui?regionId=${region.id}`;

    axios.get(url, options).then(({ data }) => {
      setWorkProducts(data.content || []);
    });
  };

  //   const filterEvent = (event) => {
  //     console.log(` event.target.title ${event.target.title}`);
  //     filterWorkProducts(event.target.title);
  //   };

  //   const loadMetaTagSelect = () => {
  //     axios.get(`/api/work-products/allMeta-Tags`).then(({ data }) => {
  //       setAllMetaTags(data.content);
  //     });
  //   };

  const loadMetaTagByRegionSelect = (id) => {
    axios.get(`/api/work-products/meta/${id}`, options).then(({ data }) => {
      setAllMetaTags(data);
      // console.log(`  allMeta-Tags = ${allMetaTags} `);
    });
  };

  const loadWorkProductImages = () => {
    axios.get(`/api/work-products/all-wp-images`, options).then(({ data }) => {
      setAllWpImages(data);
      // console.log(`  allWpImages = ${allWpImages} `);
    });
  };

  const loadWorkProducts = () => {
    // const regionId = match.params.id;
    const regionId = match;

    /*    axios.get(`/api/work-products/allMeta-Tags`)
            .then( ({data}) => {
                allMetaTags = data
            }) */

    // axios.get(`/api/work-products/ui?region.id=${regionId}`, options).then(({ data }) => {
    //   setRegion(data);
    // });

    axios.get(`/api/regions/${regionId}`, options).then((response) => {
      setRegion(response.data);
      // console.debug(`retrieved wp regions response status: ${response.data}`);
      // console.log(response.data);
      axios
        .get(`/api/work-products/ui?regionId=${regionId}`, options)
        .then((response) => {
          setWorkProducts(response.data || []);
          // console.log("wp", response.status);
        });
    });
  };

  return (
    <div style={{ padding: "0 40px" }}>
      <BreadCrumb elements={["WORK PRODUCTS", ">", region.name]} />

      <div className={"icons-container"}>
        <div style={{ textAlign: "center" }} className={"title"}>
          {searchTitle}
        </div>

        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            paddingTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          className="form-row col-md-12 offset-md-2"
        >
          <div className="form-group col-sm-3">
            {/* <Label style={{marginTop: '5px', marginBottom: '1px'}} htmlFor="searchMeta">Product Type</Label>

                            <Input style={{fontSize: '1.0em'}} id="exampleSelect"
                                   name="select"
                                   type="select" value={productKey} onChange={handleChange}>
                                <option  value={'<All>'}>{'<All>'}</option>
                                {allWpImages.map((option) => (
                                    <option  value={option.id}>{option.name}</option>
                                ))}
                            </Input> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Product Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={productKey}
                onChange={handleChange}
                label="Product Type"
              >
                <MenuItem value="<All>">{"<All>"}</MenuItem>
                {allWpImages.map((option) => (
                  <MenuItem value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {
            <div
              //   style={{ paddingLeft: "25px", marginBottom: "-8px" }}
              className="form-group col-sm-3"
            >
              {/* <FormGroup>
                <Label
                  style={{ marginTop: "1px", marginBottom: "-8px" }}
                  for="exampleSelectMulti"
                >
                  Select Meta Tags
                </Label>
                <Input
                  style={{ fontSize: "1.0em" }}
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti"
                  multiple
                  onChange={handleChangeMulti}
                >
                  {allMetaTags.map((option) => (
                    <option value={option.metaTags}>{option.metaTags}</option>
                  ))}
                </Input>
              </FormGroup> */}
              <FormControl sx={{ m: 1, width: 220 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Select Meta Tags
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  multiple
                  name="selectMulti"
                  value={metaOptions}
                  onChange={handleChangeMulti}
                  // label="Select Meta Tags"
                  input={<OutlinedInput label="Select Meta Tags" />}
                  // MenuProps={MenuProps}
                >
                  {allMetaTags.map((option) => (
                    <MenuItem value={option.metaTags}>
                      {option.metaTags}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          }

          <div
            // style={{ paddingLeft: "25px", marginBottom: "5px" }}
            className="form-group col-sm-2"
          >
            {/* <Label
              style={{ marginTop: "1px", marginBottom: "1px" }}
              htmlFor="searchMeta"
            >
              Year
            </Label>

            <Input
              style={{ fontSize: "1.0em" }}
              id="exampleSelect"
              name="select"
              type="select"
              value={searchDate}
              onChange={handleSearchDateChange}
            >
              {years.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </Input> */}
            <FormControl sx={{ m: 1, width: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={searchDate}
                onChange={handleSearchDateChange}
                label="Year"
                // MenuProps={MenuProps}
              >
                {years.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ marginTop: "5px" }} className="form-group col-sm-2">
            <div
              className={"section-icon"}
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p style={{ paddingLeft: "25px" }} htmlFor="searchMeta">
                Search
              </p>
              <Image
                style={{
                  float: "left",
                  align: "bottom",
                  paddingRight: "20px",
                  height: "45px",
                  marginRight: "1em",
                }}
                className="off-icon"
                src="front-end/icons/quick-start-guide/CSO_Programs_Hover.png"
                onClick={filterWorkProductsByMeta}
              />

              {/* <Image
                style={{ float: "left", align: "bottom", paddingRight: "20px" }}
                className="hover-icon"
                src="front-end/icons/quick-start-guide/CSO_Programs_Hover.png"
                onClick={filterWorkProductsByMeta}
              /> */}
            </div>
          </div>

          <div style={{ marginTop: "5px" }} className="form-group col-sm-2">
            <div
              className={"section-icon"}
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p style={{ paddingLeft: "-5px" }} htmlFor="searchMeta">
                Reset
              </p>
              <Image
                style={{
                  float: "left",
                  align: "bottom",
                  paddingRight: "20px",
                  height: "45px",
                  marginRight: "1em",
                }}
                className="off-icon"
                src="front-end/icons/quick-start-guide/CSO_Programs_Hover.png"
                value="reset"
                name="reset"
                onClick={filterWorkProductsByMeta}
              />

              {/* <Image
                style={{ float: "left", align: "bottom", paddingRight: "2px" }}
                className="hover-icon"
                src="front-end/icons/quick-start-guide/CSO_Programs_Hover.png"
                value="reset"
                name="reset"
                onClick={filterWorkProductsByMeta}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <WorkProductPanel region={region} workProducts={workProducts} />
    </div>
  );
};

export default WorkProducts;
