import React from 'react';
import ImagePlacerHolder from "../../assets/img/placeholder-image.png";
import { config } from '../../config';

const IMAGE_BASE_PATH = '/files';


const Image = ({src, ...props}) => {
    src = src ? src.startsWith('data:image') ? src : `${config.application.staticImages}${IMAGE_BASE_PATH}/${src}` : ImagePlacerHolder;

    return (<img src={src} {...props} />);
}

export default Image;
