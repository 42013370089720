import React, { useContext } from "react";
import SectionHeader from "../../components/SectionHeader";
import RegionTile from "../RegionTile";
import { RegionsContext } from "../../context/RegionsContext";

const Regions = () => {
  const { regions } = useContext(RegionsContext);

  return (
    <React.Fragment>
      <SectionHeader
        title={"Explore products by region"}
        id={"regions"}
        subTitle={
          "Click for country and region-specific reports and dashboards"
        }
      />

      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {regions &&
            regions.map((region, i) => {
              return (
                <div
                  key={i}
                  style={{ flex: "0 0 33.333333%", maxWidth: "33.333333%" }}
                  className={"text-center"}
                >
                  <RegionTile region={region} />
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Regions;
