import React from "react";
import Image from "../Image";
import "./styles.css";
import { Link } from "react-router-dom";

const DashboardLink = ({
  icon,
  hoverIcon,
  heading,
  subHeading,
  link,
  internalPage = false,
}) => {
  const Wrapper = internalPage
    ? ({ children }) => (
        <Link className={"dashboard-link"} to={link}>
          {" "}
          {children}
        </Link>
      )
    : ({ children }) => (
        <a className={"dashboard-link"} href={link} target={"_blank"}>
          {" "}
          {children}
        </a>
      );

  return (
    <Wrapper>
      <div className={"dashboard-icon"}>
        <div className={"img-container"}>
          <Image className="off-icon" src={icon} />
          <Image className="hover-icon" src={hoverIcon || icon} />
        </div>
        <span>
          <span className={"title"}>{heading}</span>
          <br />
          <span className={"sub-title"}>{subHeading}</span>
        </span>
      </div>
    </Wrapper>
  );
};

export default DashboardLink;
