import React from "react";
import SectionHeader from "../../components/SectionHeader";
import { csoData } from "../../data/CsoData";
import SectionTile from "../../components/SectionTile";

const LinesOfEffort = () => {
  return (
    <div className={"mb-5"}>
      <SectionHeader title={"cso's lines of effort"} />

      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {csoData &&
            csoData.map((data, i) => {
              return (
                <div
                  key={i}
                  style={{
                    marginBottom: "1.5em",
                    flex: "0 0 33.3%",
                    maxWidth: "33.3%",
                    padding: "0 15px",
                    textAlign: "center",
                  }}
                  className={"text-center"}
                >
                  <SectionTile data={data} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LinesOfEffort;
