import React from "react";
// import Row from "reactstrap/es/Row";
import SectionHeader from "../../../components/SectionHeader";
// import {deepDiveData} from "../../../data/QuickStartRecords";
import {
  subNationalOtherViews,
  actorsInvolvedInConflict1,
  quickStatistics,
  trendsOverTime,
  crossNationalComparison,
  learningNewPortfolio,
  actorsInvolvedInConflict2,
  strategicOverview,
  victimsOfConflict,
} from "../../../data/QuickStartRecords";
// import Col from "reactstrap/es/Col";
// import DashboardBigIconLink from "../../../components/DashboardBigIconLink";
import LinksSection from "../../../components/LinksSection";
// import Link from "../../../components/LinksSection/Link";
import DashboardLink from "../../../components/DashboardLink";

function MoreLinks(props) {
  return (
    <React.Fragment>
      <SectionHeader
        title={"Other Views"}
        subTitle={"Browse through example questions below"}
      />

      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Subnational"}
              className={"other-views-header"}
              links={subNationalOtherViews}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Actors Involved in Conflict"}
              className={"other-views-header"}
              links={actorsInvolvedInConflict1}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Quick Statistics"}
              className={"other-views-header"}
              links={quickStatistics}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Trends Over Time"}
              className={"other-views-header"}
              links={trendsOverTime}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Cross-National Comparison"}
              className={"other-views-header"}
              links={crossNationalComparison}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Learning a New Portfolio"}
              className={"other-views-header"}
              links={learningNewPortfolio}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Actors Involved in Conflict"}
              className={"other-views-header"}
              links={actorsInvolvedInConflict2}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Strategic Overview"}
              className={"other-views-header"}
              links={strategicOverview}
            />
          </div>

          <div
            style={{
              marginBottom: "1.5em",
              flex: "0 0 50%",
              maxWidth: "50%",
            }}
          >
            <LinksSection
              title={"- Victims of Conflict"}
              className={"other-views-header"}
              links={victimsOfConflict}
            />
          </div>
        </div>
        <div className={"mb-4"}>
          <div>
            <DashboardLink
              icon={
                "front-end/icons/quick-start-guide/I-Want-to-Explore-Where-Should-I-Start-_Off.png"
              }
              hoverIcon={
                "front-end/icons/quick-start-guide/I-Want-to-Explore-Where-Should-I-Start-_Hover.png"
              }
              link={"/app/home"}
              internalPage
              heading={"I Want to Explore! Where Should I Start?"}
              subHeading={
                "Start at the IMAP Dashboard Landing Page to see the full range of options available."
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default MoreLinks;
