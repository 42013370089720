import React from "react";

export const csoData = [
    {
        image: "front-end/icons/cso-lines-of-effort/Strategic_Prevention.png",
        title: "Strategic Prevention",
        link: "https://www.state.gov/about-us-bureau-of-conflict-and-stabilization-operations/strategic-prevention/",
        internalPage: false
    },
    {
        image: "front-end/icons/cso-lines-of-effort/Conflict_Resolution.png",
        title: "Conflict Resolution",
        link: "https://www.state.gov/about-us-bureau-of-conflict-and-stabilization-operations/conflict-resolution/",
        internalPage: false
    },
    {
        image: "front-end/icons/cso-lines-of-effort/Security_Sector_Stabilization.png",
        title: "Security Sector Stabilization",
        link: "https://www.state.gov/about-us-bureau-of-conflict-and-stabilization-operations/security-sector-stabilization-2/",
        internalPage: false
    }
]
