import React from "react";
import "./styles.css";

const SectionHeader = ({ title, subTitle, ...props }) => {
  return (
    <div {...props} className={"section-header"}>
      <hr className={"separator"} />
      <div style={{ padding: "0 40px" }}>
        {title && <h5 className="title">{title}</h5>}
        {subTitle && <h6 className="sub-title">{subTitle}</h6>}
      </div>
    </div>
  );
};

export default SectionHeader;
