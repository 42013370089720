import React from "react";

export const interactiveDashboards1 = [
    {
        icon: 'front-end/icons/dashboard/Global-Trends_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Global-Trends_Hover.png',
        heading: "Global Conflict & Protest Monthly Tracker (GCPT)",
        subHeading: "Check fatalities & events by region.",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/GCPTglobalandnationaldashboards/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/dashboard/Country_Trends_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Country_Trends_Hover.png',
        heading: "Global Trends",
        subHeading: "Check trends in fatalities and events by region",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/GlobalDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#4"
    },
    {
        icon: 'front-end/icons/dashboard/Monthly_Tracker_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Monthly_Tracker_On.png',
        heading: "Country Overview",
        subHeading: "Facts & figures for last 12 months",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryOverviewDashboard_16118615590170/OverviewDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#1"
    },
    {
        icon: 'front-end/icons/dashboard/Conflict_Explorer_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Conflict_Explorer_On.png',
        heading: "Conflict Explorer",
        subHeading: "Customize views based on the data you need",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CountryConflictExplorer/ConflictExplorer?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2"
    },
    {
        icon: 'front-end/icons/dashboard/Conflict_Actors_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Conflict_Actors_Hover.png',
        heading: "Regional Conflict Actors",
        subHeading: "Groups involved in political violence & disorder",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RegionalConflictActor_15980277376060/RegionalActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#3"
    },
    {
        icon: 'front-end/icons/dashboard/CSO_Programs_Off.png',
        hoverIcon: 'front-end/icons/dashboard/CSO_Programs_Hover.png',
        heading: "Country Trends",
        subHeading: "Customize time frame to explore subnational trends",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ConflictTrends/CountryDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y"
    },
    {
        icon: 'front-end/icons/dashboard/Country_Explorer_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Country_Explorer_Hover.png',
        heading: "Individual Conflict Actor",
        subHeading: "Deep dive on transnational actors",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/IndividualConflictActorDashboard_15980195604260/IndividualActorDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2"
    },
    {
        icon: 'front-end/icons/dashboard/StoryMap_Off.png',
        hoverIcon: 'front-end/icons/dashboard/StoryMap_Hover.png',
        heading: "Summit for Democracy Dashboard",
        subHeading: "Summit for Democracy Dashboard",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/S4DDashboardFinal/SummitforDemocracyDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#1"
    }
]


export const interactiveDashboards2 = [
    {
        icon: 'front-end/icons/dashboard/Fragility_Instability_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Fragility_Instability_Hover.png',
        heading: "Fragility & Instability",
        subHeading: "Explore indicators of conflict risk and resilience",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/RiskandResiliencyDashboard/Dashboard?%3AshowAppBanner=false&%3Adisplay_count=n&%3AshowVizHome=n&%3Aorigin=viz_share_link&%3Aembed=Y#1"
    },
    {
        icon: 'front-end/icons/dashboard/Electoral_Violence_Risk_Off.png',
        hoverIcon: 'front-end/icons/dashboard/Electoral_Violence_Risk_Hover.png',
        heading: "Electoral Violence Risk",
        subHeading: "Available to State Dept only",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/ElectoralViolenceRiskDashboard/ElectoralViolenceRiskDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#2\n"
    },
    {
        icon: 'front-end/icons/dashboard/DemocracyConflict_Off.png',
        hoverIcon: 'front-end/icons/dashboard/DemocracyConflict_Hover.png',
        heading: "Democracy and Conflict",
        subHeading: "Conflict-related democracy indicators by country",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/DemocracyGovernanceandConflictDashboardCleared/CountryDashboard2?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=Y#1"
    },
    {
        icon: 'front-end/icons/dashboard/imap-insights-off.png',
        hoverIcon: 'front-end/icons/dashboard/imap-insights-hover.png',
        heading: "Quarterly Civilian Killings Risk",
        subHeading: "Quarterly Civilian Killings Forecasting and Trends at the global and country level",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/CivilianKillingsDashboard/CivilianKillingDashboard?%3AshowAppBanner=false&%3Adisplay_count=n&%3AshowVizHome=n&%3Arender=false&%3Aorigin=viz_share_link&%3Aembed=Y#3"
    },
    {
        icon: 'front-end/icons/dashboard/imap-insights-off.png',
        hoverIcon: 'front-end/icons/dashboard/imap-insights-hover.png',
        heading: "LGBTQI+ Rights Dashboard",
        subHeading: "Explore the rights of LGBTQI+ populations on a global and country level",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/LGBTQIViolenceDashboard/GlobalView?%3AshowAppBanner=false&%3Adisplay_count=n&%3AshowVizHome=n&%3Arender=false&%3Aorigin=viz_share_link&%3Aembed=Y#3"
    },
    {
        icon: 'front-end/icons/dashboard/imap-insights-off.png',
        hoverIcon: 'front-end/icons/dashboard/imap-insights-hover.png',
        heading: "Annual Civilian Killings Dashboard",
        subHeading: "Annual Civilian Killings Forecasting and Trends at the global and country level",
        link: "https://tableauenterprise.geciq.com/t/IMAP/views/YearlyCivilianKillingsDashboard/YearlyCivilianKillingDashboard?%3AshowAppBanner=false&%3Adisplay_count=n&%3AshowVizHome=n&%3Arender=false&%3Aorigin=viz_share_link&%3Aembed=Y#3"
    }
]
