import React, { useState, useEffect } from "react";
import SectionHeader from "../SectionHeader";
import axios from "axios";
import "./styles.css";
import Image from "../Image";
import { getFileUrl } from "../WorkProductPanel";
import { config } from "../../config";
import Slider from "react-slick";
import "../../assets/css/slick/slick-theme.min.css";
import "../../assets/css/slick/slick.min.css";
import { Typography, Button } from "@mui/material";
import { signUrl } from "../../utils/util";

const FeaturedWorkProducts = () => {
  const [workProducts, setWorkProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const options = {
    headers: {
      "x-api-key": `${config.application.apiKey}`,
    },
  };

  useEffect(() => {
    loadFeaturedWorkProducts();
  }, []);

  const loadFeaturedWorkProducts = async () => {
    await axios
      .get("/api/work-products/featured/ui", options)
      .then((response) => {
        // console.debug(`retrieved featured data: ${response.status}`);
        // console.log(response.data.content);
        setWorkProducts(response.data.content || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   const loadShorCutCountries = () => {
  //     axios
  //       .get("/api/work-products/featured/ui", options)
  //       .then(({ data }) => {
  //         console.debug(`retrieved featured data: ${data}`);
  //         setWorkProducts(data.content || []);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  const settings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // console.log("wp", workProducts);

  const handleClick = async (value) => {
    // console.log("Clicked:", value);
    const val = signUrl(value);
    // console.log("val:", val);
    // Do something with the value, such as calling another function, updating state, etc.
  };

  return (
    <div className={"featured-work-products"}>
      <SectionHeader title={"featured products"} />

      <div style={{ padding: "0 40px 24px 40px" }}>
        <Slider {...settings} style={{ margin: "0 50px" }}>
          {workProducts.map((item) => {
            return (
              <div key={item.id}>
                <div className={"slide-container"}>
                  <div className={"slide-content"}>
                    <Image src={item.imageUrl} alt={item.name} />
                    <Typography
                      sx={{
                        fontSize: { xs: "20px", md: "1.25em" },
                      }}
                      className={"slide-text"}
                    >
                      <Button
                        onClick={async () => await handleClick(item)}
                        sx={{ maxHeight: "186px" }}
                      >
                        {item.name}
                      </Button>
                    </Typography>

                    <Image
                      className={"file-type-image"}
                      src={item.fileTypeImageUrl}
                      alt={item.name}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default FeaturedWorkProducts;
