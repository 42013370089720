import axios from "axios";
import { config } from "../config";
import {options} from '../Constants'


export const signUrl = async (wp) => {
  // If the File source is UPLOAD we need to sign the URL linking to our S3 bucket.
  // console.log(`wp fileSource ${JSON.stringify(wp.fileSource)}`);
  // console.log(`wp link ${wp.link}`);

  if (wp.fileSource === "URL") {
    console.log(` fileUrl ${wp.fileUrl} `);
    window.open(wp.fileUrl, "_blank", "noreferrer");
    // Using Axios to make the GET request
    // console.log(` wp  ${JSON.stringify(wp)} `)
  } else {
    if (wp.fileSource === "UPLOAD")
      return await axios
        .get(`${config.application.s3SignedURL}/imapsign?id=${wp.fileUrl}`)
        .then((response) => {
          // Return the response data as the resolved value of the Promise
          const data = response.data.url;
          //       console.log(` Signed URL = ${data} `);
          //       console.info(`${config.application.s3SignedURL}`)
          window.open(data, "_blank", "noreferrer");
        })
        .catch((error) => {
          throw error;
        });
  }
};

export const postAccess = async (id, userInfo) => {
  const email = userInfo.email ? userInfo.email : 'No Email Available';
  const name = userInfo.name ? userInfo.name : 'No Name Available';
  const zoneinfo = userInfo.zoneinfo ? userInfo.zoneinfo : 'No Zone Info Available';

//  console.log('user email: ', userInfo.email);
//  console.log('user name: ', userInfo.name);
//  console.log('user name: ', userInfo.zoneinfo);

  const user = {
    userLogin: email,
    workProductId: id,
    userName: name,
    zoneInfo: zoneinfo
   }

    return await axios
      .post(`${config.application.apiUrl}/api/work-products/access/`, user, options)
      .then((response) => {
        // Return the response data as the resolved value of the Promise
        console.info(response.data)
      })
      .catch((error) => {
        console.error(`error inserting row into work_product_access  ${error}`)
      });
      


};
