import React from "react";
import SectionHeader from "../../components/SectionHeader";
// import { csoData } from "../../data/CsoData";
// import SectionTile from "../../components/SectionTile";
// import { guidesAndInfoRecords } from "../../data/GuidesAndInfoRecords";
import { csoLearnMoreRecords } from "../../data/CsoLearnMoreRecords";

import DashboardLink from "../../components/DashboardLink";

const CSOLearnMore = () => {
  return (
    <div className={"mb-5"}>
      <SectionHeader title={"learn more about cso"} />

      <div style={{ padding: "0 40px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {csoLearnMoreRecords.map((csoLearnMoreRecord, i) => {
            return (
              <div
                key={i}
                style={{
                  marginBottom: "1.5em",
                  flex: "0 0 50%",
                  maxWidth: "50%",
                }}
              >
                <DashboardLink {...csoLearnMoreRecord} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CSOLearnMore;
