import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
// import Globe from "../../assets/img/globe.png";
// import GlobeRing from "../../assets/img/globe-ring.png";
// import TopBannerTextImage from "../../assets/img/IMAP_Banner_Text.png";
// import BannerImage from "../../assets/img/IMAP_Banner_Complete.png"
// import BannerImage from "../../assets/img/IMAP_BANNER_06302021.png"
import BannerImage from "../../assets/img/IMAPSharePointHeader2022.v2.png";
// import { Container } from "reactstrap";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useOktaAuth } from "@okta/okta-react";
import {
  Logout,
  Person,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { RegionsContext } from "../../context/RegionsContext";
import { Link, Outlet } from "react-router-dom";

const Header = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElAbout, setAnchorElAbout] = useState(null);
  const [anchorElRegion, setAnchorElRegion] = useState(null);
  const [anchorElAboutMobile, setAnchorElAboutMobile] = useState(null);
  const [anchorElRegionMobile, setAnchorElRegionMobile] = useState(null);
  const [regionsDropdownOpen, setRegionsDropdownOpen] = useState(false);
  const { regions } = useContext(RegionsContext);

  const regionsMenu = regions.map(({ name, id }) => ({
    title: name,
    link: `/app/regions/${id}/work-products`,
  }));
  // console.log("regions menu", regionsMenu);
  const pages = [
    {
      title: "Home",
      link: "/app/home",
      menu: false,
    },
    {
      title: "About",
      //   link: "",
      menu: true,
      menuItems: [
        {
          title: "IMAP",
          link: "https://www.state.gov/about-us-bureau-of-conflict-and-stabilization-operations/instability-monitoring-and-analysis-platform/",
          target: "_blank",
        },
        {
          title: "CSO",
          link: "https://www.state.gov/bureaus-offices/under-secretary-for-civilian-security-democracy-and-human-rights/bureau-of-conflict-and-stabilization-operations/",
          target: "_blank",
        },
      ],
      anchor: anchorElAbout,
      open: function handleOpenAbout(event) {
        setAnchorElAbout(event.currentTarget);
      },
      close: function handleCloseAbout() {
        setAnchorElAbout(null);
      },
      anchorMobile: anchorElAboutMobile,
      openMobile: function handleOpenAboutMobile(event) {
        setAnchorElAboutMobile(event.currentTarget);
      },
      closeMobile: function handleCloseAboutMobile() {
        setAnchorElAboutMobile(null);
      },
    },
    {
      title: "Regions",
      menu: true,
      menuItems: regionsMenu,
      anchor: anchorElRegion,
      open: function handleOpenRegion(event) {
        setAnchorElRegion(event.currentTarget);
      },
      close: function handleCloseRegion() {
        setAnchorElRegion(null);
      },
      anchorMobile: anchorElRegionMobile,
      openMobile: function handleOpenRegionMobile(event) {
        setAnchorElRegionMobile(event.currentTarget);
      },
      closeMobile: function handleCloseRegionMobile() {
        setAnchorElRegionMobile(null);
      },
    },
    {
      title: "Conflict Observatory",
      link: "/app/shortcut/work-products",
      menu: false,
    },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      const user = oktaAuth.getAccessToken() && (await oktaAuth.getUser());
      setUserInfo(user);
      // console.log("user", userInfo);
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      // console.log("token", oktaAuth.getAccessToken());
    };

    fetchUser();
  }, []);

  const isAuthenticated = authState?.isAuthenticated;

  const toggleRegionsDropdown = () =>
    setRegionsDropdownOpen(!regionsDropdownOpen);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <>
      <div style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 10 }}>
        <div className={"banner-container"}>
          <div className={"banner-inner-container"}>
            <img alt="banner" className={"banner-image"} src={BannerImage} />
          </div>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Container maxWidth="xl">
             <Toolbar>
              {isAuthenticated && (
                <>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <IconButton
                      size="large"
                      edge="start"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      {pages.map(
                        ({
                          title,
                          link,
                          menu,
                          menuItems,
                          anchorMobile,
                          openMobile,
                          closeMobile,
                        }) => (
                          <>
                            {!menu ? (
                              <MenuItem
                                key={title}
                                onClick={handleCloseNavMenu}
                              >
                                <Link
                                  key={title}
                                  className={"nav-link"}
                                  to={link}
                                  style={{ color: "black" }}
                                >
                                  {title}
                                </Link>
                              </MenuItem>
                            ) : (
                              <MenuItem
                                key={title}
                                // onClick={handleCloseNavMenu}
                              >
                                <div
                                  onClick={openMobile}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Typography textAlign="center">
                                    {title}
                                  </Typography>
                                  {anchorMobile ? (
                                    <ChevronLeft />
                                  ) : (
                                    <ChevronRight />
                                  )}
                                </div>
                                <Menu
                                  anchorEl={anchorMobile}
                                  keepMounted
                                  open={Boolean(anchorMobile)}
                                  onClose={closeMobile}
                                >
                                  {menuItems.map(({ title, link, target }) => (
                                    <Link
                                      key={title}
                                      className={"nav-link"}
                                      to={link}
                                      target={target}
                                    >
                                      <MenuItem
                                        disableRipple
                                        aria-label="nav menu"
                                        onClick={closeMobile}
                                        sx={{
                                          color: "black",
                                          "> div": {
                                            display: "flex",
                                            flexDirection: "row",
                                          },
                                        }}
                                      >
                                        {title}
                                      </MenuItem>
                                    </Link>
                                  ))}
                                </Menu>
                              </MenuItem>
                            )}
                          </>
                        )
                      )}
                    </Menu>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      gap: 10,
                    }}
                  >
                    {pages.map(
                      ({
                        title,
                        link,
                        menu,
                        menuItems,
                        anchor,
                        open,
                        close,
                      }) => (
                        <>
                          {!menu ? (
                            <Link
                              key={title}
                              className={"nav-link"}
                              to={link}
                              style={{ color: "white", display: "block" }}
                            >
                              {title}
                            </Link>
                          ) : (
                            <>
                              <div
                                onClick={open}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography textAlign="center">
                                  {title}
                                </Typography>
                                {anchor ? <ExpandLess /> : <ExpandMore />}
                              </div>
                              <Menu
                                sx={{ mt: "25px" }}
                                anchorEl={anchor}
                                keepMounted
                                open={Boolean(anchor)}
                                onClose={close}
                              >
                                {menuItems.map(({ title, link, target }) => (
                                  <Link
                                    key={title}
                                    className={"nav-link"}
                                    to={link}
                                    target={target}
                                  >
                                    <MenuItem
                                      disableRipple
                                      aria-label="nav menu"
                                      sx={{ color: "black" }}
                                      onClick={close}
                                    >
                                      {title}
                                    </MenuItem>
                                  </Link>
                                ))}
                              </Menu>
                            </>
                          )}
                        </>
                      )
                    )}
                  </Box>

                  <Box sx={{ flexGrow: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={handleOpenUserMenu}
                    >
                      <Person />
                      <span>Welcome, {userInfo && userInfo?.name}</span>
                      {anchorElUser ? <ExpandLess /> : <ExpandMore />}
                    </div>
                    <Menu
                      sx={{ mt: "25px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem
                        disableRipple
                        aria-label="logout current user"
                        onClick={handleLogout}
                      >
                        <Logout sx={{ mr: "8px" }} /> Logout
                      </MenuItem>
                    </Menu>
                  </Box>
                </>
              )}
             </Toolbar>
           </Container>
          </AppBar>
        </Box>
      </div>
      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default Header;
